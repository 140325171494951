@font-face {
    font-family: 'Nunito Sans';
    src: url('../fonts/NunitoSans-Regular.ttf');
}
*{
    font-family: 'Nunito Sans';
}
ul, p, h1, h2, h3, h4, h5, h6{
    padding: 0;
    margin: 0;
}
.nav-bottom li.active a{
    color: #ff6603;
}
.nav-bottom li a{
    color: gray;
}
.nav-bottom ul {
    display: flex;
    justify-content: space-around;
    font-family: 'Nunito Sans';
    padding: 10px 0;
}
.nav-bottom ul li{
    list-style: none;
}
.nav-bottom ul li a{
    display: flex;
    flex-direction: column;
}
.nav-bottom ul li a{
    justify-content: center;
    align-items: center;
}
.nav-bottom ul li a i, .custom-icon-home svg{
    font-size: 20px;
    margin-bottom: 5px;
}
.nav-bottom a span {
    font-size: 12px;
}
.NavbarBottom{
    position: fixed;
    width: 100%;
    bottom: 0;
    box-shadow: 0 -1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}
.not-found{
    display: block;
    height: 100vh;
    margin: 0 auto;
    width: 100%;
}
.spin-style .ant-spin{
    /* display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; */
    position: static!important;
}
.spin-style .ant-spin-dot, .spin-style .ant-spin-text {
    color: #ff6603;
}
.access-denied-container{
    background-color: blue;
    height: 100vh;
    color: #fff;
    padding: 10px;
}
.access-denied-container pre{
    margin: 0;
}
.clock-in-page {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    height: calc(100vh - 61px);
    background-color: #eaeaea;
    position: relative;
}

.info-clockin {
    background-color: #fff;
    position: absolute;
    top: 20px;
    width: 90%;
    right: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 5px;
}
.info-clockin .indicator{
    margin-left: auto;
}
.info-clockin .indicator .green{
    color: #69c150
}
.info-clockin .indicator .red{
    color: #c41447;
}
.info-clockin li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 15px;
}
.info-clockin p{
    margin-left: 10px;
}

.image-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.watch {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.clock-in-button {
    background: #69c150;
    outline: none;
    border: none;
    color: #fff;
    padding: 14px 20px;
    position: fixed;
    border-radius: 30px;
    bottom: 80px;
    right: 30px;
    z-index: 999;
}
.clock-in-button i{
    margin-right: 8px;
}
.red-button{
    background-color: #c41447;
}
.map-clockin{
    height: calc(100vh - 151px);
    width: 100%;
    top: 85px;
    position: relative;
    bottom: 0;
}
.map-clockin .leaflet-bottom.leaflet-right{
    display: none;
}
.useMobile{
    height: calc(100vh - 61px);;
    background-color: #000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.useMobile h1{
    font-size: 20px;
}
.map-clockin .info-block{
    background-color: #fff;
    padding: 30px 10px;
    margin: 0 15px;
    box-shadow: 0 -1px 5px #0003, 0 2px 2px #00000024, 0 3px 20px 0px #0000001f;
    border-radius: 5px;
}
.map-clockin .info-block .fa-android{
    color: green;
}
.map-clockin .info-block  .fa-note-sticky{
    color: #ffd83a;
}
.map-clockin .info-block h5{
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
}
.map-clockin .info-block h6{
    font-size: 16px;
    /* margin: 20px 0 5px 0; */
}
.map-clockin .info-block hr {
    margin: 15px 0;
}
.actionIsLoading{

}
.actionIsLoading{
    background: #777;
}
.actionIsLoading span.ant-spin-dot-holder{
    color: #ff680a;
}
.custom-success-modal .ant-modal-content {
    border: 2px solid #52c41a;
    border-radius: 20px;
}
.custom-error-modal .ant-modal-content {
    border: 2px solid red;
}